import $ from "jquery";
import botManager from "../bot/bot";

let _options;

let notificationsBoardElement = $("#bot-board");
let notificationsContainerElement = $("#notifications-container");
let timerContainerElement = $("#notifications-timer-container");
let notificationsRequest;
let timerLoaded = false;
let examConsumedTime;
const updateInterval = 1;

let notificationManager = {
    init: function (options) {
        _options = options;
        getEybotNotifications(true);
        loadEybotUI();
    },
    loadTimer: function (options) {
        _options = options;
        timerLoaded = true;
        notificationsRequest.abort();
        examConsumedTime = loadConsumedTime();
        showTimer();
    }
}

function getEybotNotifications(animate = false) {
    if (timerLoaded) return;
    const options = _options;
    let requestData = {};

    if (animate) {
        botManager.isAnimated(true);
    }

    notificationsRequest = $.ajax({
        headers: {
            'X-CSRF-TOKEN': options.csrfToken
        },
        url: options.indexUrl,
        method: "GET",
        data: requestData,
        dataType: "json"
    })
        .done(function (notifications) {
            let showWelcome = false;
            let notificationNumber = 0;
            $.each(notifications, (type, content) => {
                if (type === options.tutorialNotificationType) {
                    $("body").append(content);
                    showWelcome = true;
                } else {
                    $(notificationsContainerElement).append(content);
                    notificationNumber++;
                    if (notificationNumber > options.maxNumber) {
                        $(notificationsContainerElement).find(".eybot-notification").last().hide();
                    }
                }
            });
            if (animate) {
                botManager.isAnimated(false);
            }
            if (showWelcome) showWelcomeModal();
            if (notificationNumber > 0) {
                $("#notification-counter").text(notificationNumber).show();
            }
        })
        .fail(function (response,) {
            //We force failure action when loading  timer
            if (response.statusText !== "abort") {
                botManager.isAnimated(false);
                setTimeout(function () {
                    //Next updates should run in background
                    getEybotNotifications(false);
                }, options.interval);
            }
        })
}

function showWelcomeModal() {
    $('#bot-welcome-modal').modal("show");
}

function deleteEybotNotifications(notificationId) {
    const options = _options;
    let requestData = {
        _csrf_token: options.csrfToken,
    };
    return new Promise((resolve, reject) => {
        $.ajax({
            url: options.deleteUrl.replace(encodeURIComponent("%id%"), notificationId),
            method: "DELETE",
            data: requestData,
            dataType: "json"
        })
            .done(function (response) {
                resolve(response);
            })
            .fail(function (response) {
                reject(response);
            });
    });
}

function loadEybotUI() {
    $(document).ready(function () {
        $(document).on("click", "#bot-board-close", function (event) {
            hideNotificationsBoard();
            event.preventDefault();
        });
        $(document).on("click", ".delete-notification", function (event) {
            const notification = $(this).closest(".eybot-notification");
            const originalDeleteHtml = $(this).html();
            $(this)
                .width($(this).width())
                .height($(this).height())
                .html("<i class=\"fa fa-spinner fa-spin\" style=\"line-height: " + $(this).css('line-height') + "\"'></i>");
            $(".delete-notification").addClass("disabled");
            deleteEybotNotifications($(notification).data("notificationId"))
                .then(response => {
                    $(this).html(originalDeleteHtml);
                    $(".delete-notification").removeClass("disabled");
                })
                .catch(response => {
                    $(this).html(originalDeleteHtml);
                    $(".delete-notification").removeClass("disabled");
                });
        });
        $(document).on("click", "#notification-counter", function (event) {
            event.preventDefault();
            showNotificationsBoard();
        });
    });
}

function showNotificationsBoard() {
    $(notificationsBoardElement).addClass("bot-board-active");
    botManager.update();
}

function hideNotificationsBoard() {
    $(notificationsBoardElement).removeClass("bot-board-active");
}

function loadConsumedTime() {
    const options = _options;
    let localStorageConsumedTime = localStorage.getItem(options.timer.localstorageKey);

    if (options.timer.init || localStorageConsumedTime === null) {
        return 0;
    } else {
        return localStorageConsumedTime;
    }
}

function setConsumedTime() {
    const options = _options;
    localStorage.setItem(options.timer.localstorageKey, examConsumedTime);
}

function showTimer() {
    const options = _options;
    $(notificationsContainerElement).empty();
    $(notificationsBoardElement).find(".bot-board-header").hide();
    $(notificationsBoardElement).addClass("timerLoaded");
    $(timerContainerElement).find(".message").text(options.timer.message);
    updateTimer();
    showNotificationsBoard();
}

function updateTimer() {
    const options = _options;
    const maxTime = options.timer.max;
    const time = examConsumedTime < maxTime ? parseInt(examConsumedTime) + updateInterval : maxTime;
    const progressWidth = time < maxTime ? (time / maxTime) * 100 : 100;
    examConsumedTime = time;

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const prettyTime = String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');

    $(timerContainerElement).find(".timer").text(prettyTime);
    $(notificationsBoardElement).find(".progress-bar").css("width", progressWidth+"%");


    if (time % options.timer.interval === 0) {
        setConsumedTime()
    }

    if(time < maxTime) {
        setTimeout(function () {
            updateTimer();
        }, (updateInterval * 1000));
    }
}

export default notificationManager;
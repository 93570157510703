import $ from "jquery";

const audioUtils = require("./audioUtils");
const crypto = require("crypto");
const v4 = require("./aws-signature-v4");
const marshaller = require("@aws-sdk/eventstream-marshaller");
const util_utf8_node = require("@aws-sdk/util-utf8-node");
const mic = require("microphone-stream");

const eventStreamMarshaller = new marshaller.EventStreamMarshaller(
    util_utf8_node.toUtf8,
    util_utf8_node.fromUtf8
);

let _options;

let transcribeManager = {
    init: function (options = {}) {
        _options = options;

        setUIButtonsSelectors();

        if (this.areMediaDevicesSupportedByBrowser()) {
            bootTranscribeUIHandlers();
        } else {
            setDisabledTranscriptionButtons(
                _options.messages.errors.unsupported_browser
            );
        }
    },
    areMediaDevicesSupportedByBrowser: function () {
        return window.navigator.mediaDevices.getUserMedia;
    }
};

export default transcribeManager;

const REGION = "eu-west-1";
const LANGUAGE_CODE = "en-US";
const SAMPLE_RATE = 44100;

// Audio conversion, streaming and transcription via web sockets
let inputSampleRate;
let transcription;
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

// Transcription UI elements
let activeTranscriptionArea;
let startButton;
let stopButton;
let clearButton;
let errorsContainer;
let descriptionArea;

function setUIButtonsSelectors() {
    startButton = $(_options.ui.start_button.selector);
    stopButton = $(_options.ui.stop_button.selector);
    clearButton = $(_options.ui.clear_button.selector);
    errorsContainer = $(_options.ui.errors_container.selector);
    descriptionArea = $(_options.ui.transcription_textarea.selector);
}

function bootTranscribeUIHandlers() {
    startButton.click(startButtonHandler);
    clearButton.click(clearButtonHandler);
    stopButton.click(stopButtonHandler);
    descriptionArea.keyup((e) => {
        handleClearButtonDisabled(e.target);
    });
}

let startButtonHandler = function () {
    transcription = "";

    errorsContainer.hide();
    activeTranscriptionArea = $(this)
        .closest("div.transcription-container")
        .find("textarea.situation-question-answer");

    setButtonsStatusForStartTranscription($(this));

    window.navigator.mediaDevices
        .getUserMedia({
            video: false,
            audio: true
        })
        .then(streamAudioToWebSocket)
        .catch(function (error) {
            showError(_options.messages.errors.streaming);
            setButtonsStatusForStopTranscription();
        });
};

let stopButtonHandler = function (e) {
    e.preventDefault();

    closeSocket();
    setButtonsStatusForStopTranscription();

};

let clearButtonHandler = function (e) {
    let transcriptionArea = $(this)
        .closest("div.transcription-container")
        .find("textarea.situation-question-answer");

    e.preventDefault();

    transcriptionArea.val("");
    transcription = "";
    handleClearButtonDisabled(transcriptionArea);
};


let handleClearButtonDisabled = function (textArea) {
    let closestClearButton = $(textArea)
        .closest("div.transcription-container")
        .find(_options.ui.clear_button.selector);

    if ($(textArea).val().length > 0) {
        closestClearButton.prop("disabled", false);
    } else {
        closestClearButton.prop("disabled", true);
    }
}


let streamAudioToWebSocket = function (userMediaStream) {
    micStream = new mic();

    micStream.on("format", function (data) {
        inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);

    let url = createPresignedUrl();

    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    socket.onopen = function () {
        micStream.on("data", function (rawAudioChunk) {
            let binary = convertAudioToBinaryMessage(rawAudioChunk);
            if (socket.readyState === socket.OPEN) socket.send(binary);
        });
    };

    wireSocketEvents();
};

function wireSocketEvents() {
    socket.onmessage = function (message) {
        let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
        let messageBody = JSON.parse(
            String.fromCharCode.apply(String, messageWrapper.body)
        );
        if (messageWrapper.headers[":message-type"].value === "event") {
            handleEventStreamMessage(messageBody);
        } else {
            transcribeException = true;

            showError(messageBody.Message);
            setButtonsStatusForStopTranscription();
        }
    };

    socket.onerror = function () {
        socketError = true;

        showError(_options.errors.websocket_connection);
        setButtonsStatusForStopTranscription();
    };

    socket.onclose = function (closeEvent) {
        micStream.stop();

        if (!socketError && !transcribeException) {
            if (closeEvent.code != 1000) {
                showError(
                    _options.messages.exceptions.streaming + ": " + closeEvent.reason
                );
            }

            setButtonsStatusForStopTranscription();
        }
    };
}

let handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;

            transcript = decodeURIComponent(escape(transcript));

            activeTranscriptionArea.val(transcription + transcript + "\n");

            if (!results[0].IsPartial) {
                activeTranscriptionArea.scrollTop(
                    activeTranscriptionArea[0].scrollHeight
                );
                transcription += transcript + "\n";
            }
        }
    }
    if (activeTranscriptionArea.val().length > 0) {

    }
};

let closeSocket = function () {
    if (socket.readyState === socket.OPEN) {
        micStream.stop();

        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);

        socket.send(emptyBuffer);
    }
};

function setButtonsStatusForStartTranscription(startButtonClicked) {
    let relatedStopButton = startButtonClicked.siblings(
        "button.stop-transcription-btn"
    );
    startButtonClicked.find("span.text").text(_options.ui.start_button.recording || "Recording....");
    relatedStopButton.prop("disabled", false);
    startButton.prop("disabled", true);
    stopButton.not(relatedStopButton).prop("disabled", true);
}

function setButtonsStatusForStopTranscription() {
    startButton
        .prop("disabled", false)
        .find("span.text").text(_options.ui.start_button.label || "Start");
    stopButton.each(function(){
        $(this).prop("disabled", true);
        let closestTextarea = $(this)
            .closest("div.transcription-container")
            .find(_options.ui.transcription_textarea.selector);
        handleClearButtonDisabled(closestTextarea);
    });
}

function setDisabledTranscriptionButtons(title = "") {
    startButton
        .prop("disabled", true)
        .attr(
            "title",
            title || _options.ui.start_button.title || "Start Transcription"
        );

    stopButton
        .prop("disabled", true)
        .attr(
            "title",
            title || _options.ui.start_button.title || "Stop Transcription"
        );

    clearButton
        .prop("disabled", true)
        .attr(
            "title",
            title || _options.ui.start_button.title || "Clear Transcription"
        );

    botManager.loadAndShow("audioTranscriptionIncompatibility", null, true);
}

function showError(message) {
    errorsContainer.children("span.error-text").text(message);
    errorsContainer.show();
}

function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);

    if (raw == null) return;

    let downSampledBuffer = audioUtils.downsampleBuffer(
        raw,
        inputSampleRate,
        SAMPLE_RATE
    );
    let pcmEncodedBuffer = audioUtils.pcmEncode(downSampledBuffer);
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    return eventStreamMarshaller.marshall(audioEventMessage);
}

function getAudioEventMessage(buffer) {
    return {
        headers: {
            ":message-type": {
                type: "string",
                value: "event"
            },
            ":event-type": {
                type: "string",
                value: "AudioEvent"
            }
        },
        body: buffer
    };
}

function createPresignedUrl() {
    let endpoint = "transcribestreaming." + REGION + ".amazonaws.com:8443";

    return v4.createPresignedURL(
        "GET",
        endpoint,
        "/stream-transcription-websocket",
        "transcribe",
        crypto
            .createHash("sha256")
            .update("", "utf8")
            .digest("hex"),
        {
            key: _options.aws_keys.aws_transcribe_access_key_id,
            secret: _options.aws_keys.aws_transcribe_secret_access_key_id,
            sessionToken: "",
            protocol: "wss",
            expires: 15,
            region: REGION,
            query:
                "language-code=" +
                LANGUAGE_CODE +
                "&media-encoding=pcm&sample-rate=" +
                SAMPLE_RATE
        }
    );
}

import $ from "jquery";

let _options;
let heldHref = false;

let examManager = {
    init: function (options) {
        _options = options.options;
        loadDismissAction();
        document.addEventListener("examDismissEvent", function (e) {
            if (heldHref) {
                window.location.href = heldHref;
            }
        });
    }
};

function loadDismissAction() {
    $(document).on("click", function (event) {
        const target = event.target;
        if ($("#bot-tooltip").is(':hidden') && $(target).parents(_options.clickable_area_selector).length !== 1
            && $(target).parents(".bot-area").length !== 1
            && $(target).parents("#bot-tooltip").length !== 1
            && !("skipDismissExam" in target.dataset)
            && ((typeof $(target).attr('href') !== "undefined") || (typeof $(target).parent().attr('href') !== "undefined"))) {
            heldHref = $(target).attr('href');
            botManager.loadAndShow("exam", event, true);
        }
    });
}

export default examManager;
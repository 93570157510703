/**
 * Use this file for requiring/writing specific .js and .css code
 * related with the web application you're developing.
 */

require("./app");
require("bootstrap");

// TODO: Refactor location por common (FE and BO) plugins
require("../../bo/base-ui/assets/plugins/select2/dist/css/select2.min.css");
require("select2");


// AWS - Transcribe
import transcribeManager from "./aws-transcribe/lib/transcribe-manager";
window.transcribeManager = transcribeManager;

import aqManager from "./cc/clinical_case/situation/answered_questions/answered-questions-manager";
window.aqManager = aqManager;

// Notifications
import notificationManager from "./notification/notification";
window.notificationManager = notificationManager;

// Bot
import botManager from "./bot/bot";
import eybotContent from "./bot/data/eybot.json";
window.botManager = botManager;
botManager.load(eybotContent);

// Tutorial
import tutorialTtManager from "./bot/tutorial/tooltip";
window.tutorialTtManager = tutorialTtManager;

// Player
import playerManager from "./player/player";
window.playerManager = playerManager;

//Exams
import examManager from "./bot/exam/exam";
window.examManager = examManager;
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/fe.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

window.jQuery = $;
window.$ = $;

$(document).ready(() => {
  $("#mas-info").click((ev) => {
    ev.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $("#whats").offset().top,
      },
      500
    );
  });
  $("#temas").click((ev) => {
    ev.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $("#tematicas").offset().top,
      },
      500
    );
  });
  $(document).ready(function () {
    $("label.tree-toggler").click(function () {
      $(this).parent().children("ul.tree").toggle(300);
    });
  });

  // $("#btnValidateCCQuestion").click(function(event){
  //     event.preventDefault();
  //
  //     $('.expected').show();
  // });
});

$.fn.extend({
  treed: function (o) {
    var openedClass = "fa-caret-up";
    var closedClass = "fa-caret-down";

    if (typeof o != "undefined") {
      if (typeof o.openedClass != "undefined") {
        openedClass = o.openedClass;
      }
      if (typeof o.closedClass != "undefined") {
        closedClass = o.closedClass;
      }
    }
    //initialize each of the top levels
    var tree = $(this);
    tree.addClass("tree");
    tree
      .find("li")
      .has("ul")
      .each(function () {
        var branch = $(this); //li with children ul
        branch.prepend("<i class='indicator fas " + closedClass + " '></i>");
        branch.addClass("branch");
        branch.on("click", function (e) {
          if (this == e.target) {
            var icon = $(this).children("i:first");
            icon.toggleClass(openedClass + " " + closedClass);
            $(this).children().children().toggle();
          }
        });
        branch.children().children().toggle();

        if ($("li").hasClass("active")) {
          $(".active ul li"); //.show(); uncomment to open the first tree upon rendering the page
        }
      });
    //fire event from the dynamically added icon
    tree.find(".branch .indicator").each(function () {
      $(this).on("click", function () {
        $(this).closest("li").click();
      });
    });
    //fire event to open branch if the li contains an anchor instead of text
    tree.find(".branch>a").each(function () {
      $(this).on("click", function (e) {
        $(this).closest("li").click();
        e.preventDefault();
      });
    });

    //fire event to open branch if the li contains a button instead of text
    tree.find(".branch>button").each(function () {
      $(this).on("click", function (e) {
        $(this).closest("li").click();
        e.preventDefault();
      });
    });
  },
});

//Initialization of treeviews

$("#tree1").treed();

$("#tree2").treed({ openedClass: "caret-down", closedClass: "caret-up" });

$("#tree3").treed({
  openedClass: "glyphicon-chevron-right",
  closedClass: "glyphicon-chevron-down",
});

var element = $(".floating-chat");

setTimeout(function () {
  element.addClass("enter");
}, 1000);

element.click(openElement);

function openElement() {
  //element.find('>i').hide();
  element.addClass("expand");
  element.find(".chat").addClass("enter");

  element.off("click", openElement);
  element.find(".header button").click(closeElement);
}

function closeElement() {
  element.find(".chat").removeClass("enter").hide();
  element.find(">i").show();
  element.removeClass("expand");
  element.find(".header button").off("click", closeElement);

  setTimeout(function () {
    element.find(".chat").removeClass("enter").show();
    element.click(openElement);
  }, 500);
}

var close = $(".without-self");

close.click(closeElement);

const navbarToggler = document.getElementsByClassName("navbar-toggler")[0];
const moreInfoButton = document.getElementsByClassName("btn-more")[0];
const registerButton = document.getElementById("registerButton");

navbarToggler.onclick = () => {
    registerButton.classList.add("jorge")
  if (moreInfoButton.classList.contains("d-none")) {
    moreInfoButton.classList.remove("d-none");
    registerButton.classList.remove("d-none");
  } else {
    moreInfoButton.classList.add("d-none");
    registerButton.classList.add("d-none");
  }
};

import $ from "jquery";

let _options;

let aqManager = {
  init: function(options) {
    _options = options;

    bootFormHandlers();
  }
};

export default aqManager;

const DEFAULT_ERROR_TEXT = "Answers could not be validated!";

function bootFormHandlers() {
  let checkAnswersButton = $(_options.check_answers_button_id);

  checkAnswersButton.click(function(e) {
    let answersContainer = $(_options.answers_container);
    let csrfToken = _options.csrf_token;
    let storeUrl = _options.store_url;
    let answeredQuestions = {};
    let expectedAnswersContainer = $(_options.expected_answer_container);
    let errorsContainer = $(_options.errors_container);

    hideErrors(errorsContainer);
    hideExpectedAnswers(expectedAnswersContainer);

    answersContainer.each(function() {
      answeredQuestions[$(this).data("question-id")] = $(this)
        .val()
        .trim();
    });

    let requestData = {
      _csrf_token: csrfToken,
      answered_questions: answeredQuestions
    };

    $.ajax({
      url: storeUrl,
      method: "POST",
      data: requestData,
      dataType: "json"
    })
      .done(function(response) {
        showExpectedAnswers(expectedAnswersContainer, response.data);
      })
      .fail(function(response) {
        let errorText =
          400 === response.status
            ? response.responseJSON.data.message
            : DEFAULT_ERROR_TEXT;

        showErrors(errorsContainer, errorText);
      });

    e.preventDefault();
  });
}

function showExpectedAnswers(expectedAnswersContainer, questions) {
  let expectedQuestionContainer;

  questions.forEach(function(question) {
    expectedQuestionContainer =
      "#expected-answer-question-" + question.question_id;
    $(expectedQuestionContainer)
      .find("p.answer-text")
      .text(question.expected_answer);
  });

  expectedAnswersContainer.show();
}

function hideExpectedAnswers(expectedAnswersContainer) {
  expectedAnswersContainer.hide();
}

function showErrors(errorsContainer, errorText) {
  errorsContainer.children("span.error-text").text(errorText);
  errorsContainer.show();
}

function hideErrors(errorsContainer) {
  errorsContainer.hide();
}

import $ from "jquery";
import Popper from "popper.js";

let _options;

let tutorialTtManager = {
    init: function (options) {

        _options = options;

        const ttTooltip = document.querySelector("#tt-tooltip");

        if (ttTooltip) {

            const tutorialDomElementId = $(ttTooltip).data("tutorialDomElementId");

            const popcorn = document.querySelector(tutorialDomElementId);
            const tooltip = document.querySelector("#tt-tooltip");

            $(document).ready(function () {
                new Popper(popcorn, tooltip, {
                    placement: 'auto',
                    modifiers: {
                        arrow: {
                            element: '.arrow'
                        },
                        offset: {
                            offset: "0,20"
                        }
                    }
                });
                //Prevent to attach tooltip to hidden elements
                $(popcorn).show().parents().show();
                $(popcorn).show();
                $(tooltip).show();
                $(tooltip).on("click", ".finish", function(){
                    $(tooltip).hide();
                })
            });
        }
    }
}

export default tutorialTtManager;
import $ from "jquery";
let _options;
let audio;

let playerManager = {
    init: function (options) {
        _options = options;
        const player = document.querySelector(_options.player.selector);
        audio = document.querySelector(_options.player.selector + " audio");
        setUIButtonsSelectors();
        if (audio.canPlayType('audio/mpeg') === "probably" || audio.canPlayType('audio/mpeg') === "maybe") {
            bootTranscribeUIHandlers();
        } else{
            $(player).hide();
            botManager.loadAndShow("audioPlaybackIncompatibility", null, true);
        }

    }
};

export default playerManager;

let playButton;
let pauseButton;
let stopButton;

function setUIButtonsSelectors() {
    playButton = $(_options.player.ui.play_button.selector);
    pauseButton = $(_options.player.ui.pause_button.selector);
    stopButton = $(_options.player.ui.stop_button.selector);

    $(playButton).width($(playButton).width() + 10);

    audio.addEventListener('play', (e) => {
       audioPlayingHandler(e);
    });

    audio.addEventListener('pause', (e) => {
        audioPausedHandler(e);
    });
}

function bootTranscribeUIHandlers() {
    playButton.click(playButtonHandler);
    pauseButton.click(pauseButtonHandler);
    stopButton.click(stopButtonHandler);
}

let playButtonHandler = function (e) {
    e.preventDefault();
    audio.play();
};

let pauseButtonHandler = function (e) {
    e.preventDefault();
    audio.pause();
};

let stopButtonHandler = function (e) {
    e.preventDefault();
    audio.pause();
    audio.currentTime = 0;
};

let audioPlayingHandler = function (e) {
    $(playButton)
        .prop('disabled', true)
        .find('.text').html(_options.player.ui.play_button.playing || "playing...");
    $(pauseButton).prop('disabled', false);
    $(stopButton).prop('disabled', false);
}

let audioPausedHandler = function (e) {
    $(playButton)
        .prop('disabled', false)
        .find('.text').html(_options.player.ui.play_button.label || "play");
    $(pauseButton).prop('disabled', true);
    $(stopButton).prop('disabled', true);
}

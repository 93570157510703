import $ from "jquery";
import Popper from "popper.js";

let popper;
let eybot = $("#bot-board-gem img");
let inanimate;
let _eybotContent;
let _options;

let botManager = {
    load: function (eybotContent) {
        _eybotContent = eybotContent;
    },
    init: function (options) {
        _options = options;
        this.isAnimated(true, 2000);

        $(document).ready(function () {
            $("#bot-board-gem").on("click", function () {
                popper = toggleBotTooltip("show");
            });
            $(document).on("click", "#bot-tooltip-close", function (event) {
                toggleBotTooltip("hide", event);
            });
            $(document).on("click", "#bot-tooltip-content .btn", function (event ) {
                const slice = $(this).data("slice");
                if (typeof slice !== "undefined" && slice.length > 0) {
                    loadContent(slice, event);
                } else {
                    toggleBotTooltip("hide", null);
                }
            });
        });
    },
    isAnimated: function (animate, timeOut = 0) {

        if (animate) {
            clearTimeout(inanimate);
            $(eybot)
                .attr("src", $(eybot).attr("src").replace("static", "animated"))
                .addClass("animated");
            if (timeOut > 0) {
                inanimate = setTimeout(function () {
                    botManager.isAnimated(false);
                }, timeOut);
            }
        } else {
            $(eybot)
                .attr("src", $(eybot).attr("src").replace("animated", "static"))
                .removeClass("animated");
        }
        botManager.update();
    },
    loadAndShow(slice, event = null, force = false) {
        if(event !== null) event.preventDefault();
        toggleBotTooltip("show", event, slice, force);
    },
    update() {
        //TODO: Find a way to retrieve popper element and call update
        if (popper) {
            popper.update();
        }
    },
    hide() {
        toggleBotTooltip();
    }
}

function toggleBotTooltip(action, event = null, slice = null, force = false) {
    const popcorn = document.querySelector("#bot-board-gem");
    const tooltip = document.querySelector("#bot-tooltip");
    const tooltipOverlay = document.querySelector("#bot-tooltip-overlay");
    const botBoard = document.querySelector("#bot-board");
    const contentSlice = slice ? slice : _options.slice;

    if (action == "show") {
        if (!force && $(eybot).hasClass("animated")) return;
        //$("#bot-board").removeClass("bot-board-active");
        botManager.isAnimated(true, 1000);
        const popper = new Popper(popcorn, tooltip, {
            placement: 'top',
            modifiers: {
                flip: {
                    enabled: false
                },
                preventOverflow: {
                    escapeWithReference: true
                },
                arrow: {
                    element: '.arrow'
                },
                offset: {
                    offset: "-42%p,30"
                }
            }
        });
        loadContent(contentSlice);
        $(botBoard).addClass("bot-tooltip-visible")
        $(tooltip).show();
        $(tooltipOverlay).show();

        return popper;

    } else {
        botManager.isAnimated(false);
        $(botBoard).removeClass("bot-tooltip-visible")
        $("#bot-tooltip-help").show();
        $("#bot-tooltip-quit").hide();
        $(tooltip).hide();
        $(tooltipOverlay).hide();

        if (event) event.preventDefault();
        return null;
    }
}

function loadContent(slice, event = null) {
    const contents = resolve(slice, _eybotContent);
    let content = '' +
        '<h3 class="title">' + _eybotContent.title[_options.lang] + '</h3>' +
        '<p class="text-blue-edr text-center">' + contents.text[_options.lang] + '</p>';
    /*_eybotContent[slice].options[0].options.forEach((option) => {*/

    contents.options.forEach((option) => {

        content = content.concat(
            '<a data-slice="'+ (option.slice || '') +'" class=" btn btn-ed-blue" href="'+ (option.link || '') +'" target="' + (option.target || '_self') + '">\n' +
            option.text[_options.lang] +
            '</a>');
    });

    content = content.concat('<div class="w-100 text-center">\n' +
        '                <a id="bot-tooltip-close" class="tooltip-close text-uppercase"\n' +
        '                   href="#"> ' + _options.texts.close + ' </a>\n' +
        '            </div>')

    $("#bot-tooltip-content").html(content);
    botManager.update();
    if (event) event.preventDefault();

}

function resolve(path, obj) {
    let content = path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self);

    //Prevent infinite loop forcing to return content for default slice
    if(typeof content !== 'undefined' || path === "default") {
        return content;
    } else{
        return resolve("default", obj);
    }
}

export default botManager;